export default {
    values: "Valeurs disponibles",
    info: "Informations",
    administration: {
        index: "Administration",
        territoires: {
            index: "Territoires",
            add: "Ajouter un territoire",
            is_private: {
                true: "Territoire privé",
                false: "Territoire commune(s)",
            },
            export: "Exporter les territoires",
            view: "Fiche territoire", /*  {code} */
            edit: "Fiche territoire",
            delete: {
                index: "Supprimer",
                title: "Demande de suppression",
                text: "Le territoire {code} va être supprimé. <br/><li>Ses contacts seront supprimés</li><li>Les acteurs appartenants à ce territoire seront déselectionnés dans les PEI</li><li>Les utilisateurs appartenants à ce territoire n'auront plus de territoire attribué</li> <br/> Souhaitez-vous continuer ?"
            },
            liste: "Liste des territoires",
            code: 'Code',
            name: "Nom",
            emails: "Emails",
            peis_count: "PEI",
            users_count: "Utilisateurs",
            communes_count: "Communes",
            created_at: 'Date de création',
            updated_at: 'Date de modification',
            type: {
                index: 'Type de territoire',
                sdis: 'Centre de secours',
                interco: 'Intercommunalité',
                commune: 'Commune',
                compagnie: 'Centre mixte',
                departement: 'Département',
                groupement: 'Groupement',
                autre: 'Autre',
                _PRIVATE_: 'Territoire privé',
            },
            toaster: {
                POST: {
                    success: {
                        title: "Territoire sauvegardé",
                        text: "Le territoire {name} a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Territoire sauvegardé",
                        text: "Le territoire {name} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Territoire supprimé",
                        text: "Le territoire {name} a bien été supprimé",
                    },
                },
            },
        },
        homepage_html: {
            toaster: {
                PUT: {
                    success: {
                        title: "Page d'accueil sauvegardée",
                        text: "Le contenu de la page d'accueil a bien été mis à jour",
                    },
                },
            }
        },
        contact_html: {
            toaster: {
                PUT: {
                    success: {
                        title: "Informations de contact sauvegardées",
                        text: "Le contenu des informations de contact a bien été mis à jour",
                    },
                },
            }
        },
        utilisateurs: {
            index: "Utilisateurs",
            add: "Ajouter un utilisateur",
            export: "Exporter les utilisateurs",
            view: "Fiche utilisateur",
            edit: "Fiche utilisateur",
            credentials: {
                created_at: "Compte créé le"
            },
            delete: {
                index: "Désactiver",
                title: "Demande pour désactiver un utilisateur",
                text: "L'utilisateur {name} va être désactivé. <br/> Il ne pourra plus se connecter, ni recevoir des notifications. <br/> Souhaitez-vous continuer ?"
            },
            restore: "Activer",
            username: 'Identifiant',
            name: 'Nom',
            email: 'Email',
            last_connexion: 'Dernière connexion',
            password: 'Mot de passe',
            passwordConfirm: 'Confirmation du mot de passe',
            territory_id: "Territoire",
            receive_notifications: 'Recevoir les notifications',
            territory: {
                index: 'Territoire associé',
                v: 'Territoire associé',
                t: 'Territoire associé',
                name: 'Territoire associé',
            },
            role: {
                index: 'Rôle',
                user: 'Utilisateur SDIS',
                admin: 'Administrateur',
                collectivity: 'Collectivité',
                aep: 'AEP', // 84
                simple: 'Simple', // 17
                manager: 'Gestionnaire SDIS',
            },
            has_trashed: {
                index: "Statut du compte",
                false: "Exclure les désactivés",
                true: "Seulement les désactivés",
                all: "Tous les utilisateurs"
            },
            created_at: 'Date de création',
            updated_at: 'Date de modification',
            toaster: {
                POST: {
                    success: {
                        title: "Utilisateur sauvegardé",
                        text: "L'utilisateur {name} a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Utilisateur sauvegardé",
                        text: "L'utilisateur {name} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Utilisateur désactivé",
                        text: "L'utilisateur {name} a bien été désactivé",
                    },
                },
            },
        },
        acteurs: {
            index: 'Acteurs',
            export: "Exporter les acteurs",
            add: "Ajouter un acteur",
            view: "Fiche acteur",
            edit: "Fiche acteur",
            delete: {
                index: "Supprimer",
                title: "Demande de suppression",
                text: "L'acteur {t} va être supprimé.<br/>Souhaitez-vous continuer ?"
            },
            code: "Code",
            raison_sociale: "Raison sociale",
            name: "Raison sociale",
            phone: "Téléphone",
            address_first_line: "Adresse",
            address_second_line: "Complément adresse",
            address_postal_code: "Code postal",
            address_city: "Ville",
            documents: "Documents associés",
            type: {
                index: "Type",
                exploitant: "Exploitant",
                syndicat_des_eaux: "Syndicat des eaux",
                intercommunalite: "Intercommunalité",
                mairie: "Mairie",
                societe_privee: "Société privée",
                proprietaire: "Propriétaire",
            },
            territory_id: "Territoire associé",
            territory: {
                id: "Territoire associé",
                t: "Territoire associé",
                v: "Territoire associé",
                index: "Territoire associé",
                name: "Territoire associé",
            },
            toaster: {
                POST: {
                    success: {
                        title: "Acteur sauvegardé",
                        text: "L'acteur {name} a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Acteur sauvegardé",
                        text: "L'acteur {name} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Acteur supprimé",
                        text: "L'acteur {name} a bien été supprimé",
                    },
                },
            },
        },
        configuration: {
            index: "Paramètres",
        },
    },
    all: 'tous',
    contact: {
        index: "Contact",
    },
    contacts: {
        phone: "Téléphone",
        email: "E-mail",
    },
    export: {
        pei_pdf: "Fiche information PDF",
        pei_sheet_pdf: "Fiche terrain PDF",
    },
    false: 'Non',
    gestion: {
        pei: {
            anomaly_state: {
                b: 'Anomalies bloquantes',
                er : 'Anomalies emploi restreint',
                nb: 'Anomalies non bloquantes'
            },
            alerts: {
                name: "Alerte",
                duration_seconds: "Durée",
                status: {
                    index: "Statut",
                    Current: "En cours",
                    Terminated: "Terminé",
                },
                pei_effect_name: {
                    index: "Répercussion sur le statut",
                    harmless: "Aucun effet",
                    restricted_use: "Emploi restreint",
                    blocking: "Indisponible",
                }
            },
            toaster: {
                POST: {
                    success: {
                        title: "Point d'eau incendie",
                        text: "Le PEI {k} a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Point d'eau incendie",
                        text: "Le PEI {k} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Point d'eau incendie",
                        text: "Le PEI {k} a été archivé",
                    },
                },
            },
            index: "Points d'eau incendie",
            add: "Ajouter un PEI",
            export: "Exporter les PEI",
            liste: "Liste des PEI",
            carte: "Carte des PEI",
            view: "Fiche PEI <small>{numero_long}</small>",
            edit: "Fiche PEI <small>{numero_long}</small>",
            project_active: "Rendre actif",
            askDelete: {
                index: "Demande suppression",
                title: "Demande de suppression d'un PEI",
                text: "Vous allez déposer une demande de suppression pour le PEI {k}. <br>Veuillez renseigner la raison de votre demande : <br>",
                toaster: {
                    POST: {
                        success: {
                            title: "Point d'eau incendie",
                            text: "La demande de suppression du PEI a bien été envoyée",
                        },
                    },
                },
            },
            delete: {
                index: "Archiver",
                title: "Demande d'archivage",
                text: "Le PEI {k} va être archivé. Il n'apparaitra plus par défaut dans les tournées, la carte et toutes les autres fonctionnalités d'Hydraclic. <br/> Souhaitez-vous continuer ?"
            },
            restore: "Restaurer",
            pdf: "Télécharger",
            commune_code: "Commune",
            commune: {
                index: "Commune",
                nom: "Commune",
            },
            where_en_projet: {
                index: "En projet",
                all: "Inclure les projets",
                true: "Seulement les projets",
                false: "Exclure les projets",
            },
            where_has_active_deletion_demands: {
                index: "Demandes de suppression",
                all: "Inclure les demandes de suppression",
                true: "Seulement les demandes de suppression",
                false: "Exclure les demandes de suppression",
            },
            where_has_late_ct: {
                index: 'Contrôles techniques',
                warning: "Bientôt en retard",
                danger: "En retard",
                ok: "En règle",
                none: "Aucune date",
            },
            where_has_late_ro: {
                index: 'Reconnaissances opérationnelles',
                warning: "Bientôt en retard",
                danger: "En retard",
                ok: "En règle",
                none: "Aucune date",
            },
            where_has_anomalies: {
                index: 'Contient des anomalies',
                all: "Inclure les PEI avec et sans anomalies",
                true: "Seulement les PEI avec des anomalies",
                false: "Exclure les PEI avec des anomalies",
            },
            where_has_anomaly: "Anomalies",
            where_has_private_territory: "Est situé sur à un territoire privé",
            has_trashed: {
                index: "Archives",
                false: "Exclure les archivés",
                true: "Seulement les archivés",
                all: "Inclure les archivés"
            },
            interco: {
                index: "Intercommunalité",
                t: "Intercommunalité",
            },
            number_address: "No",
            street_address: "Adresse",
            territory_id: "Territoire",
            address_complement: "Complément d'adresse",
            address_cp: "Code postal",
            lat: "Latitude",
            lng: "Longitude",
            numero_long: "Numéro long",
            numero_court: "Numéro court",
            numero_prefix: "Préfixe du numero long",
            etat_start: {
                index: "État",
                inconnu: "Inconnu",
                disponible: "Disponible",
                emploi_restreint: "Emploi restreint",
                indisponible: "Indisponible",
            },
            status: {
                index: "Statut",
                non_defini: "Non défini",
                prive: "Privé",
                prive_conventionne: "Privé conventionné",
                public: "Public",
            },
            type_hydrant: {
                borne_agricole: "Borne agricole",
                bouche: "Bouche incendie",
                citerne: "Citerne",
                index: "Famille de PEI",
                legend: {
                    borneagricole_disponible: "Borne agricole disponible",
                    borneagricole_emploirestreint: "Borne agricole emploi restreint",
                    borneagricole_indisponible: "Borne agricole indisponible",
                    bouche_disponible: "Bouche incendie disponible",
                    bouche_emploirestreint: "Bouche incendie emploi restreint",
                    bouche_indisponible: "Bouche incendie indisponible",
                    citerne_disponible: "Citerne disponible",
                    citerne_indisponible: "Citerne indisponible",
                    citerne_emploirestreint: "Citerne emploi restreint",
                    reserve_disponible: "Réserve ou citerne disponible",
                    reserve_indisponible: "Réserve ou citerne indisponible",
                    reserve_emploirestreint: "Réserve ou citerne emploi restreint",
                    pointaspiration_disponible: "Point d'aspiration disponible",
                    pointaspiration_emploirestreint: "Point d'aspiration emploi restreint",
                    pointaspiration_indisponible: "Point d'aspiration indisponible",
                    poteau_disponible: "Point incendie disponible",
                    poteau_emploirestreint: "Point incendie emploi restreint",
                    poteau_indisponible: "Point incendie indisponible",
                    poteaurelais_disponible: "Poteau relais disponible",
                    poteaurelais_emploirestreint: "Poteau relais emploi restreint",
                    poteaurelais_indisponible: "Poteau relais indisponible",

                    // ----- 84 ------
                    penabaignable_disponible: "PENA baignable disponible",
                    penabaignable_emploirestreint: "PENA baignable emploi restreint",
                    penabaignable_indisponible: "PENA baignable indisponible",
                    // ---------- 17 --------------
                    // poteau_relais_incendie_disponible ...
                    // TODO
                },
                point_aspiration: "Point d'aspiration",
                poteau: "Poteau incendie",
                poteau_relais: "Poteau relais",
                reserve: "Réserve ou citerne",
                // ----- 84 ------
                pena_baignable: "PENA baignable",

                // ----- 17 ------
                poteau_relais_incendie: "Poteau relais incendie",
                PI80: 'Poteau incendie 80',
                PI100: 'Poteau incendie 100',
                PI150: 'Poteau incendie 150',
                BI80: 'Bouche incendie 80',
                BI100: 'Bouche incendie 100',
                BI150: 'Bouche incendie 150',
                REA: 'Réserve en eau alimentée',
                RENA: 'Réserve en eau non alimentée',
                PAP: 'Point d\'aspiration permanent',
                PAV: 'Point d\'aspiration variable',
                PUISARD: 'Puisard',
                CS: 'Colonne humide / sèche',
            },
            type: {
                index: "Type",
                hydrant: "Hydrant",
                pena: "PENA"
            },
            zone_tour: {
                index: "Tournées",
                id: "Tournées",
            },
            last_technical_control: {
                pression: "Pression",
                pression_statique: "Pression statique",
                debit_1bar: "Débit nominal", //  en m3/h sous 1 bar mini
                debit_max: "Débit maximal",
                date_control: "Date CT",
                controller_name: "Contrôle réalisé par",
                volume_pa: "Volume",
                stakeholder: {
                    index: "Prestataire",
                    t: "Prestataire",
                    v: "Prestataire",
                },
                stakeholder_id: "Prestataire",
                method: {
                    index: "Méthode de contrôle",
                    echantillonnage: "Échantillonnage",
                    mesure_physique: "Mesure physique",
                    simulation: "Simulation",
                },
            },
            last_operational_recognition: {
                date_control: "Date RO",
                controller_name: "Reconnaissance réalisée par",
                centre_secours: "Centre de secours",
                centre_mixte: "Centre mixte",
                centre_mixte_territory: {
                    index: "Centre mixte",
                    name: "Centre mixte",
                    t: "Centre mixte",
                },
                centre_secours_territory: {
                    index: "Centre de secours",
                    name: "Centre de secours",
                    t: "Centre de secours",
                }
            },
            operational_recognitions: {
                centre_secours_territory_id: "Centre de secours",
            },
            perenne: "Pérenne",
            acces_4x4: "Accès 4x4",
            esi_equipee: "ESI équipée",
            emploi: {
                index: "Emploi",
                DECI: "DECI",
                DFCI: "DFCI",
                ICPE: "ICPE",
                tooltip: {
                    DECI: "Défense Extérieure Contre l'Incendie",
                    DFCI: "Défense de la Forêt contre les Incendies",
                    ICPE: "Installations classées protection de l'environnement",
                },
            },
            type_aspiration: "Type d'aspiration",
            date_test_aspiration: "Date de test d'aspiration",
            en_projet: "En projet",
            num_permis: "N° de dossier d'urbanisme",
            num_webprev: "N° de Dossier WEBPREV",
            permis_construire: "Permis de construire ",
            type_prise: {
                index: "Type de prise",
            },
            autonomie_minutes: "Temps d'utilisation réel",
            date_debut_indispo: "Début d'indisponibilité",
            date_fin_indispo: "Fin d'indisponibilité",
            diametre_canalisation_mm: "Diamètre de la canalisation",
            diametre_hydrant_mm: "Diamètre de l'hydrant",
            type_reseau: "Type de réseau",
            nom_ressource_realim: {
                index: "Nom de la ressource",
                source: "Source",
                riviere: "Rivière",
            },
            volume_realim_pibi: "Capacité du château d'eau",
            debit_realim_pa: "Débit de réalimentation",
            autorite_police: "Autorité de police DECI",
            service_public_deci: "Service public DECI",
            service_public_ep: "Gestionnaire du réseau",
            exploitant_ep: "Exploitant",
            proprietaire_ep: "Propriétaire du réseau",
            proprietaire_prive: "Propriétaire privé",
            date_mise_service: "Date mise en service",
            annee_fabrication: "Année de fabrication",
            marque: "Marque",
            modele: "Modèle",
            materiau_coffre: "Type de coffre",
            service_gestionnaire: "Service gestionnaire",
            plan: "Coordonnées Atlas urbain",
            antivol: "Dispositif sécurisation",
            trace_choc: "Dispositif antichoc",
            a_distance: "Mesure connectée",
            geometry: "Géométrie",
            anomalies: {
                index: "Anomalies",
                b: "Bloquante",
                er: "Emploi restreint",
                nb: "Non bloquante",
            },
            updated_at: "Date de modification",
            template: {
                basic: "Affichage par défaut",
                ct: "Suivi des contrôles techniques",
                ro: "Suivi des reconnaissances opérationnelles",
            },
            simultaneousControls: {
                pei: {
                    numero_long: "Numéro long",
                },
                debit: "Débit du contrôle",
                pression: "Pression du contrôle",
            },
        },
        tournees: {
            index: "Tournées",
            add: "Ajouter une tournée",
            export: "Exporter les tournées",
            edit: "Modifier une tournée",
            view: "Voir une tournée",
            delete: {
                index: "Supprimer",
                title: "Demande de suppression",
                text: "La tournée {number} va être supprimée.<br/>Souhaitez-vous continuer ?"
            },
            credentials: {
                updated_at: "Date de mise à jour",
            },
            territory_id : "Territoire",
            commune_code: "Commune",
            commune: {
                index: "Commune",
                nom: "Commune",
            },
            number: "Code",
            type: {
                index: "Type",
                zone: "Zone",
                echantillonnage: "Échantillonnage",
            },
            color: "Couleur attribué",
            peis_count: "Nombre de PEI",
            updated_at: "Date de mise à jour",
            toaster: {
                POST: {
                    success: {
                        title: "Tournées",
                        text: "La tournée {code} a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Tournées",
                        text: "La tournée {code} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Tournées",
                        text: "La tournée {code} a été supprimé",
                    },
                },
            },
        },
        indisponibilitesprogrammees: {
            index: "Indisponibilités programmées",
            view: "Voir une indisponibilité programmée",
            edit: "Modifier une indisponibilité programmée",
            add: "Ajouter une indisponibilité",
            export: "Exporter les indisponibilités",
            delete: {
                index: "Supprimer",
                title: "Suppression d'une indisponibilité",
                text: "L'indisponibilité {name} va être supprimée définitivement. <br/> Souhaitez-vous continuer ?"
            },
            id: "Numéro",
            name: "Nom de l'indisponibilité",
            address: "Adresse",
            commune_code: "Commune",
            commune: {
                index: "Commune",
                nom: "Commune",
            },
            traffic_status: {
                index: "État de la circulation",
                no_impact: "Pas d'impact",
                cut: "Coupée",
                single: "Circulation à un sens",
                alternate: "Circulation alternée",
            },
            repeat: {
                index: "Répétition",
                none: "Aucune répétition",
                monthly: "Tous les mois",
                weekly: "Toutes les semaines",

            },
            pei_etat_start: {
                index: "Statut des PEI",
                disponible: "Disponible",
                emploi_restreint: "Emploi restreint",
                indisponible: "Indisponible"
            },
            start_at: "Début de l'évènement",
            end_at: "Fin de l'évènement",
            end_repeat_at: "Fin de répétition",
            description: "Description",
            toaster: {
                POST: {
                    success: {
                        title: "Indisponibilités programmées",
                        text: "L'indisponibilité {name} a bien été créée",
                    },
                },
                PUT: {
                    success: {
                        title: "Indisponibilités programmées",
                        text: "L'indisponibilité {name} a bien été mise à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Indisponibilités programmées",
                        text: "L'indisponibilité {name} a été supprimée",
                    },
                },
            },
        },
        envoidemail: {
            toaster: {
                POST: {
                    success: {
                        title: "Envoi de mail",
                        text: "L'envoi de mail a bien été configuré",
                    },
                },
            },
            index: "Envoi de mail",
            add: "Nouvel e-mail",
            view: "Envoi de mail",
            communes: {
                index: "Communes",
                code: "Communes",
                nom: "Communes",
            },
            content: "Contenu de l'e-mail",
            communes_complete: "Communes",
            template: {
                index: "Modèle",
            },
            email_template: {
                index: "Modèle",
                name: "Modèle",
            },
            subject: "Objet",
            recipients: "Destinataires",
            credentials: {
                created_at: "Date de l'envoi",
                created_by: {
                    name: "Émetteur"
                },
            },
        },
        import: {
            index: "Import des PEI",
            add: "Nouvel import ",
            edit: "Vérification de l'import",
            view: "Détail de l'import",
            delete: {
                index: "Supprimer le fichier validé",
                title: "Suppression de l'import pret",
                text: "Le fichier a été validé mais <b>l'import n'a pas été fait</b>. <br>Souhaitez-vous supprimer définitivement ce fichier ?"
            },
            title: "Titre",
            file_name: "Nom du fichier",
            status: {
                index: "Statut",
                validated: "Pret à être importé",
                done: "Terminé",
                error: "Erreur lors de l'import"
            },
            toaster: {
                PUT: {
                    success: {
                        title: "Import des PEI",
                        text: "L'import {title} a été fait",
                    },
                },
                DELETE: {
                    success: {
                        title: "Import des PEI",
                        text: "L'import {title} a été supprimé",
                    },
                },
            }
        },
        historique: {
            index: "Historique",
            export: "Exporter l'historique",
            pei_revision: "Révision",
            numero_long: "Numéro long",
            commune_code: "Commune",
            object_type: {
                index: "Type",
                TechnicalControl: "Contrôle technique",
                OperationalRecognition: "Reconnaissance opérationnelle",
                Pei: "Point d'eau incendie",
            },
            after_values: "Détail",
            commune: {
                nom: "Commune",
            },
            app: {
                index: "Provient de",
                web: "Application web",
                mobile: "Application mobile",
                import: "Import web",
                system: "Système",
            },
            pei: {
                numero_long: "Numéro long",
            },
            event_type: {
                index: "Action",
                created: "Création",
                updated: "Modification",
                deleted: "Archivage",
                restored: "Restauration",
            },
            type: {
                index: "Action",
                created: "Création",
                updated: "Modification",
                deleted: "Restauration",
            },
            credentials: {
                created_at: "Date de l'évènement",
                created_by: {
                    name: "Utilisateur",
                    index: "Utilisateur",
                },
            },
        },
        controlessimultanes: {
            index: "Contrôles simultanés",
            add: "Ajouter un contrôle simultané",
            view: "Voir un contrôle simultané",
            edit: "Modifier un contrôle simultané",
            export: "Exporter les contrôles simultanés",
            delete: {
                index: "Supprimer",
                title: "Suppression d'un contrôle simultané",
                text: "Le contrôle simultané {id} va être supprimé définitivement. <br/> Souhaitez-vous continuer ?"
            },
            numero_long: "Numéro long des PEI",
            id: "Numéro",
            k: "Numéro",
            date_control: "Date du contrôle",
            commune_code: "Commune",
            commune: {
                index: "Commune",
                nom: "Commune",
            },
            place: "Lieu",
            comment: "Informations complémentaires",
            peis: "PEI contrôlés",
            pei: {
                numero_long: "Numéro long",
            },
            debit: "Débit",
            pression: "Pression",
            updated_at: "Date de mise à jour",
            toaster: {
                POST: {
                    success: {
                        title: "Contrôles simultanés",
                        text: "Le contrôle a bien été créé",
                    },
                },
                PUT: {
                    success: {
                        title: "Contrôles simultanés",
                        text: "Le contrôle {id} a bien été mis à jour",
                    },
                },
                DELETE: {
                    success: {
                        title: "Contrôles simultanés",
                        text: "Le contrôle {id} a été supprimé",
                    },
                },
            },
        },
    },
    legend: {
        peis: "Point d'eau incendie",
        cadastre: "Communes & cadastre",
        tournees: "Tournées",
        geometry: "Géométrie",
        indisponibilitesprogrammees: "Indisdisponibilités programmées"
    },
    no: 'Non',
    notifications: {
        name: "Nom du modèle",
        subject: "Sujet de l'e-mail",
        pei_created: "Création d'un PEI",
        etat_start_changed: "Changement d'état d'un PEI",
        geometry_changed: "Changement de localisation d'un PEI",
        pei_deleted: "Archivage d'un PEI",
        status_changed: "Changement de statut d'un PEI",
        deletion_demand_created: "Demande de suppression d'un PEI",
        toaster: {
            PUT: {
                success: {
                    title: "Notifications",
                    text: "La notification a bien été enregistrée",
                },
            },
        },
        roles_recipients: {
            index: "Utilisateurs",
            admin: "Administrateur",
            user: 'Utilisateur SDIS',
            collectivity: 'Collectivité',
            aep: 'AEP',
            simple: 'Simple',
            manager: 'Gestionnaire SDIS',
        },
        stakeholder_types_recipients: {
            index: "Acteurs",
            autorite_police: "Autorité de police DECI",
            service_public_deci: "Service public DECI",
            service_public_ep: "Gestionnaire du réseau",
            exploitant_ep: "Exploitant",
            proprietaire_ep: "Propriétaire du réseau",
            proprietaire_prive: "Propriétaire privé",
            exploitant: "Exploitant",
            syndicat_des_eaux: "Syndicat des eaux",
            intercommunalite: "Intercommunalité",
            mairie: "Mairie",
            societe_privee: "Société privée",
            proprietaire: "Propriétaire",
        },
        territory_types_recipients: {
            index: 'Type de territoire',
            sdis: 'Centre de secours',
            interco: 'Intercommunalité',
            commune: 'Commune',
            compagnie: 'Centre mixte',
            departement: 'Département',
            groupement: 'Groupement',
            autre: 'Autre',
            _PRIVATE_: 'Territoire privé',
        },

    },
    profil: {
        index: "Mon profil",
    },
    ressources: {
        index: "Ressources",
    },
    stats: {
        peis_count: {
            index: "Points d'eau incendie",
            link: "Accéder aux PEI"
        },
        sdis: {
            index: "Centres de secours",
            link: "Accéder aux PEI"
        },
        compagnie: {
            index: "Centres mixtes",
            link: "Accéder aux PEI"
        },
        tour_count: {
            index: "Tournées",
            link: "Accéder aux tournées"
        },
        peis_by_state: "Répartition par état des PEI",
        peis_by_status: "Répartition par statut des PEI",
        peis_by_type: "Répartition par type d'hydrant des PEI",
        ct: "Statut des contrôles techniques",
        ro: "Statut des reconnaissances opérationnelles",
        ctro: "Suivi des Contrôles techniques et Reconnaissances opérationnelles",
    },
    tableaudebord: {
        index: "Tableau de bord",
        export: "Exporter les statistiques de mon territoire",
    },
    tableaudebordwithfilter: {
        index: "Tableau de bord",
        export: "Exporter les statistiques de la sélection",
    },
    template: {
        toaster: {
            POST: {
                success: {
                    title: "Modèles d'e-mail",
                    text: "Le modèle {name} a bien été créé",
                },
            },
            PUT: {
                success: {
                    title: "Modèles d'e-mail",
                    text: "Le modèle {name} a bien été enregistré",
                },
            },
        },
    },
    true: 'Oui',
    update_all: {
        toaster: {
            PUT: {
                success: {
                    title: "Modification de masse",
                    text: "La modification des données a bien fonctionné",
                },
            },
        }
    },
    yes: 'Oui',
    display_all_territories: 'Afficher tous les territoires non modifiable'
}
