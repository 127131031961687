import lang_fr from './lang/fr';
import library_fr from './packages/datakode/nuxt-datakode-utils/lang/fr';
import permissions_fr from './lang/permissions.fr';


// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deepMerge(obj1, obj2) {
    for (const key in obj2) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj2.hasOwnProperty(key)) {
            if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
                obj1[key] = deepMerge(obj1[key], obj2[key]);
            } else {
                obj1[key] = obj2[key];
            }
        }
    }
    return obj1;
}

// eslint-disable-next-line prefer-const
let lang= {
    ...lang_fr,
    ...permissions_fr,
    ...library_fr,
}

// TODO verifier  si 17
// merger lang_fr17 seulement si env 17 (comment ???)
//
// lang= deepMerge(lang, lang_fr17);


export default defineI18nConfig(() => ({
    legacy: false,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    warnHtmlMessage: false,
    locale: 'fr',
    runtimeOnly: false,
    messages: {
        fr: lang
    }
}))
